<!--
 * @Author: your name
 * @Date: 2020-12-04 19:38:01
 * @LastEditTime: 2021-05-19 18:48:34
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \one\src\views\record\search\index.vue
-->
<template>
  <div class="theallclass">
    <navigation :msg="msg"></navigation>
    <div class="topsearch1">
      <input v-model="semsg" placeholder="姓名/手机号" />
      <img @click="selectmeth" src="../../../assets/search.png" alt="" />
    </div>
    <div class="selectmsgclass">
      <div class="ztcl">
        <img src="../../../assets/redBar.png" />
        <div>报告状态</div>
      </div>
      <div class="selectmenuclass">
        <div
          @click="clickmenu(item)"
          :class="index == 0 ? 'menucla' : 'menucl'"
          v-for="(item, index) in menulist1"
          v-bind:key="item"
        >
          {{ item }}
        </div>
      </div>

      <div class="ztcl ztcl2">
        <img src="../../../assets/redBar.png" />
        <div>授权状态</div>
      </div>
      <div class="selectmenuclass">
        <div
          @click="clickmenu(item)"
          :class="index == 0 ? 'menucla' : 'menucl'"
          v-for="(item, index) in menulist2"
          v-bind:key="item"
        >
          {{ item }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {
   
  },
  data() {
    return {
      msg: "背调记录",
      semsg:"",
      menulist1: [
        "全部",
        "已完成背调",
        "未完成背调",
        "背调进行中",
        "待支付",
        "授权被拒绝",
        "超时未授权"
      ],
      menulist2: ["全部", "短信授权", "二维码授权"],
    };
  },
  methods: {
    selectmeth() {
      if(!this.semsg){
        this.$toast("请输入搜索内容");
        return;
      }
      this.$router.push({
        path: "searchmsg",
        query: {
          ismeth:true,
          selectmsg: this.semsg,
        },
      });
    },
    clickmenu(msg) {
      this.$router.push({
        path: "searchmsg",
        query: {
          selectmsg: msg,
        },
      });
    },
  },
};
</script>
<style scoped>
.menucla {
  background-color: #fdddcf;
  color: #f64100;
  font-size: 0.24rem;
  line-height: 0.33rem;
  border-radius: 0.05rem;
  padding: 0.08rem 0.26rem;
  margin-left: 0.18rem;
  margin-bottom: 0.2rem;
}
.ztcl2 {
  margin-top: 0.2rem;
}
.menucl {
  font-size: 0.24rem;
  line-height: 0.33rem;
  color: #616b80;
  background-color: #f0f2f5;
  border-radius: 0.05rem;
  padding: 0.08rem 0.26rem;
  margin-left: 0.18rem;
  margin-bottom: 0.2rem;
}
.selectmenuclass {
  margin-left: 0.18rem;
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.21rem;
}
.ztcl {
  display: flex;
  align-items: center;
  font-size: 0.3rem;
  line-height: 0.35rem;
  color: #333333;
  font-weight: 600;
}
.ztcl img {
  width: 0.11rem;
  height: 0.38rem;
  margin-right: 0.23rem;
}
.selectmsgclass {
  margin: 0.33rem;
  margin-top: 0.6rem;
}
.theallclass {
  height: 100%;
  background-color: #ffffff;
}
.topsearch1 img {
  width: 0.35rem;
  height: 0.35rem;
}
.topsearch1 input{
  width: 100%;
}
.topsearch1 {
  display: flex;
  background-color: #f0f2f5;
  align-items: center;
  margin: 0.31rem 0.3rem;
  padding: 0.2rem 0.34rem;
  align-items: center;
  justify-content: space-between;
  font-size: 0.28rem;
  line-height: 0.4rem;
  border-radius: 0.1rem;
}
input {
  border: none;
  background-color: #f0f2f5;
}
input::placeholder {
  color: #616b80;
}
</style>